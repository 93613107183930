// import React from 'react';
// import { graphql } from 'gatsby';

// import Layout from '../components/Layout';
// import PageHeadSection from '../components/PageHeadSection';
// import SEO from '../components/SEO';
// import ClosedDealSection from '../components/ClosedDealSection';

// export const query = graphql`
//   {
//     allAptoRecord {
//       edges {
//         node {
//           id
//           name
//           date
//           type
//           imageURL
//           localImage {
//             childImageSharp {
//               fluid(maxWidth: 450, maxHeight: 300) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//           property {
//             name
//             description
//             imageURL
//           }
//           propertyLocalImage {
//             childImageSharp {
//               fluid(maxWidth: 450, maxHeight: 300) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// const ClosedDealsPage = ({ data }) => {
//   return (
//     <Layout>
//       <SEO title="Closed Deals" />
//       <PageHeadSection title="Closed Deals" />
//       <ClosedDealSection
//         color="white"
//         size="medium"
//         backgroundImage=""
//         backgroundImageOpacity={1}
//         title=""
//         subtitle="Etiam porta sem malesuada magna mollis euismod. Nullam quis risus eget urna mollis ornare vel eu leo. Maecenas sed diam eget risus varius blandit sit amet non magna."
//         data-aos="fade-up"
//         deals={data.allAptoRecord.edges}
//       />
//     </Layout>
//   );
// };

// export default ClosedDealsPage;

import React from 'react';

function ClosedDeals() {
  return (
    <div>
      <h1>Hello, world</h1>
    </div>
  );
}

export default ClosedDeals;

